<template>
  <div id="app">
    <div v-if="devEnvironment" class="env-info">DEV</div>
    <SubletHeader
      :side-menu-open="sideMenuVisible"
      @showSubletInformationClick="handleShowSubletInformationToggle"
    />
    <SubletRenewalModal
      v-if="appReady && authenticated && renewalModalVisible"
      :is-loading="extendApplicationLoading"
      @response="handleRenewalResponse"
    />
    <SubletInformationModal
      v-else-if="appReady && authenticated && subletInformationModalVisible"
      :has-read-info="hasReadInfo"
      title="Information om uthyrning i andra hand"
      @confirmAndCloseInfoModal="handleUpdateHasReadInfo(application.id)"
      @closeInfoModal="subletInformationModalVisible = false"
    />
    <div class="app-inner-wrapper">
      <SubletSideMenu
        v-if="!isLoading && authenticated"
        :visible="sideMenuVisible"
        :toggle-side-menu="toggleSideMenuVisible"
        @close="toggleSideMenuVisible(false)"
      />

      <main class="app-inner-wrapper-main">
        <div v-if="isLoading" class="app-spinner-wrapper">
          <BaseLoadingSpinner />
          <p class="app-spinner-label">Hämtar information...</p>
        </div>
        <div v-else class="content-wrapper">
          <transition
            name="fade-list"
            mode="out-in"
            appear
            @enter="forceScrollTop"
          >
            <div :key="$route.path">
              <router-view />
            </div>
          </transition>
        </div>
        <SupportButton></SupportButton>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import SubletSideMenu from './components/SubletSideMenu.vue';
import SubletHeader from '@/components/SubletHeader.vue';
import SubletInformationModal from '@/components/SubletInformationModal.vue';
import SubletRenewalModal from '@/components/SubletRenewalModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import SupportButton from '@/components/SupportButton.vue';

export default {
  name: 'SubletApp',
  components: {
    SubletSideMenu,
    SubletHeader,
    SupportButton,
    SubletInformationModal,
    SubletRenewalModal,
    BaseLoadingSpinner
  },
  data() {
    return {
      subletInformationModalVisible: true,
      extendApplicationLoading: false
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.app.isLoading,
      appReady: state => state.app.appReady,
      authenticated: state => state.app.authenticated,
      application: state => state.subletApplication.application,
      sideMenuVisible: state => state.app.sideMenuVisible,
      renewalModalVisible: state => state.app.renewalModalVisible
    }),
    ...mapGetters({
      hasReadInfo: 'subletApplication/hasReadInfo',
      applicationIsSentAndApproved:
        'subletApplication/applicationIsSentAndApproved',
      applicationIsSentAndArchived:
        'subletApplication/applicationIsSentAndArchived'
    }),
    ableToCreateNewApplication() {
      return (
        this.applicationIsSentAndArchived || this.applicationIsSentAndApproved
      );
    },
    devEnvironment() {
      if (process.env.NODE_ENV === 'production') {
        return false;
      }
      return true;
    }
  },
  watch: {
    ableToCreateNewApplication: {
      handler(isAbleToCreateNewApplication) {
        if (isAbleToCreateNewApplication) {
          this.setRenewalModalVisible(true);
          this.subletInformationModalVisible = false;
        }
      },
      immediate: true
    }
  },
  async created() {
    window.addEventListener('resize', this.getAndsetScreenSize);
    this.getAndsetScreenSize();
    await this.initiate();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getAndsetScreenSize);
  },
  methods: {
    ...mapActions({
      toggleSideMenuVisible: 'app/toggleSideMenuVisible',
      getAndsetScreenSize: 'app/getAndsetScreenSize',
      fetchApplication: 'subletApplication/fetchApplication',
      initiate: 'app/initiate',
      updateHasReadInfoCheck: 'subletApplication/updateHasReadInfoCheck',
      extendAhApplication: 'subletApplication/extendAhApplication',
      createNewAhApplication: 'subletApplication/createNewAhApplication'
    }),
    ...mapMutations({
      setRenewalModalVisible: 'app/setRenewalModalVisible'
    }),
    forceScrollTop() {
      this.$nextTick(() => {
        window.scroll(0, 0);
      });
    },
    handleUpdateHasReadInfo(applicationId) {
      this.updateHasReadInfoCheck(applicationId);
      this.subletInformationModalVisible = false;
    },
    handleShowSubletInformationToggle() {
      this.subletInformationModalVisible = !this.subletInformationModalVisible;
    },
    async handleRenewalResponse(response) {
      if (response === 'EXTEND') {
        this.extendApplicationLoading = true;
        await this.extendAhApplication();
        this.$router.push('/uppgifter');
        this.extendApplicationLoading = false;
      }
      if (response === 'CREATE_NEW') {
        this.extendApplicationLoading = true;
        await this.createNewAhApplication();
        this.$router.push('/uppgifter');
        this.extendApplicationLoading = false;
      }
      if (response === 'CLOSE') {
        this.setRenewalModalVisible(false);
        this.subletInformationModalVisible = false;
      } else {
        this.setRenewalModalVisible(false);
        this.subletInformationModalVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sublet-page-header {
  :deep(.text-container) {
    padding-top: 0;
  }
}
</style>
