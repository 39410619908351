<template>
  <SubletPage>
    <section class="status-text-wrapper">
      <div class="dynamic-text-container">
        <BaseImage
          v-if="!applicationIsEditable"
          class="icon"
          :url="svgs.ICONS.CHECK_GREEN_CIRCLE"
        />
        <BaseImage v-else class="icon" :url="svgs.ICONS.BLUE_EXCLAMATION" />
        <div v-if="applicationIsSentAndApproved">
          <h2 class="dynamic-title">Aktiv uthyrning</h2>
          <p>
            Grattis! Din ansökan om andrahandsuthyrning har blivit godkänd. Din
            uthyrningsperiod är från <strong>{{ startDate }}</strong> till
            <strong>{{ endDate }}</strong
            >. Under denna period är det viktigt att följa avtalet som är
            upprättat med din hyresvärd. Om du önskar kan du välja att förlänga
            eller skapa en helt ny ansökan.
          </p>
          <br />
          <BaseButton
            :is-rounded="true"
            type="button"
            class="modal-btn"
            :disabled="isLoading"
            :is-loading="isLoading"
            @click="setRenewalModalVisible(true)"
          >
            Hantera ny ansökan
            <BaseIconText
              class="info-modal-arrow-right"
              :icon-url="svgs.ICONS.ARROW_RIGHT"
            >
            </BaseIconText>
          </BaseButton>
        </div>
        <template v-else>
          <h2 class="dynamic-title">
            {{ statusTitle }}
          </h2>
          <h3 v-if="applicationComplete">Vad händer nu?</h3>
          <p v-if="applicationComplete && applicationInProgress">
            Du har nu fyllt i alla uppgifter och laddat upp alla dokument som
            krävs för att skicka in ansökan till oss. När du klickat på skicka
            in kommer det inte vara möjligt att göra några ändringar innan vi
            granskat ansökan.
            <router-link
              style="
                color: #878b9d;
                text-decoration: underline;
                cursor: pointer;
              "
              to="/granska"
              onmouseover="this.style.color='#409fff';"
              onmouseout="this.style.color='#878b9d';"
              >Klicka här</router-link
            >
            för att gå till sidan där du kan skicka in ansökan.
          </p>
          <div
            v-else-if="applicationComplete && !applicationIsEditable"
            class="process-step-container"
          >
            <div>
              <h2>Steg 1 - Vi på Bytesansökan granskar dina uppgifter</h2>
              <p>
                {{ statusTextStep1 }}
              </p>
            </div>
            <div>
              <h2>Steg 2 - Din ansökan skickas in till din hyresvärd</h2>
              <p>
                {{ statusTextStep2 }}
              </p>
            </div>
          </div>
          <p v-else>
            {{ statusText }}
          </p>
          <p v-if="!applicationIsEditable && !applicationCategoryRejected">
            Vi håller tummarna att ni får till er andrahandsuthyrning!
          </p>

          <div
            v-if="applicationIsEditable && !applicationComplete"
            class="category-link-container"
          >
            <ul>
              <li
                v-for="item in getUncompletedCategory(filteredMenu)"
                :key="item.url"
              >
                <router-link class="category-link" :to="item.url" exact>
                  {{ item.label }}
                </router-link>
              </li>
            </ul>
          </div>

          <div
            v-if="applicationCategoryRejected"
            class="category-link-container"
          >
            <ul>
              <li v-for="item in rejectCategoriesLinks" :key="item.url">
                <router-link class="category-link" :to="item.url" exact>
                  {{ item.label }}
                </router-link>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </section>
  </SubletPage>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import svgs from '@/utils/icon-constants';
import SubletPage from '@/components/SubletPage.vue';
import BaseImage from '@/components/BaseImage.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'Status',
  components: { SubletPage, BaseImage, BaseButton },
  data() {
    return {
      svgs,
      completedStatuses: ['COMPLETE', 'APPROVED']
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    ...mapGetters({
      filteredMenu: 'app/filteredMenu',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      applicationCategoryRejected:
        'subletApplication/applicationCategoryRejected',
      applicationComplete: 'subletApplication/applicationComplete',
      applicationCategoriesWithErrors:
        'subletApplication/applicationCategoriesWithErrors',
      categoryRejected: 'subletApplication/categoryRejected',
      rejectedCategories: 'subletApplication/rejectedCategories',
      applicationIsSentAndApproved:
        'subletApplication/applicationIsSentAndApproved'
    }),
    startDate() {
      return new Date(this.application?.startDate).toLocaleDateString('sv-SE');
    },
    endDate() {
      return new Date(this.application?.endDate).toLocaleDateString('sv-SE');
    },
    applicationStatus() {
      return this.application?.status || '';
    },
    applicationInProgress() {
      return this.applicationStatus === 'WAITING';
    },
    applicationReadyForSigning() {
      return this.applicationStatus === 'AWAITINGSIGN';
    },
    applicationSigned() {
      return this.applicationStatus === 'READY';
    },
    applicationSentToHost() {
      return this.applicationStatus === 'SENT';
    },
    statusTitle() {
      if (this.applicationComplete && this.applicationInProgress) {
        return 'Ansökan ifylld och redo för att skickas in!';
      }
      if (this.applicationComplete && !this.applicationIsEditable) {
        return 'Ansökan inskickad för granskning!';
      }
      if (this.applicationCategoryRejected) {
        return 'Du behöver komplettera uppgifter';
      }
      if (this.applicationReadyForSigning) {
        return 'Ansökan redo för signering!';
      }
      if (this.applicationSigned) {
        return 'Ansökan signerad!';
      }
      if (this.applicationSentToHost) {
        return 'Ansökan inskickad till hyresvärd!';
      }
      return 'Det saknas fortfarande uppgifter från dig';
    },
    statusTextStep1() {
      return 'Du har nu skickat in ansökan och vi påbörjar granskningen av era uppgifter. När vi säkerställt att alla uppgifter är korrekta uppdaterar vi den här sektionen när det är dags för er att signera ansökan. Om du behöver komplettera med några uppgifter kommer det också att stå här.';
    },
    statusTextStep2() {
      return 'När du har signerat din ansökan kommer vi att skicka vidare den till din hyresvärd som sedan kommer att återkoppla till dig med beslut. Vänligen notera att handläggningstiden för andrahandsuthyrning kan hos hyresvärden ta upp till 2 månader.!';
    },
    statusText() {
      if (this.applicationCategoryRejected) {
        return 'Vi har nu granskat din ansökan. Du behöver komplettera med uppgifter under följande kategorier:';
      }
      if (this.applicationReadyForSigning) {
        return 'Vi har nu slutfört granskningen av era uppgifter och ansökan är godkänd. Det som återstår är att signera ansökan!';
      }
      if (this.applicationSigned) {
        return 'Alla parter har nu signerat, inom kort kommer ansökan att skickas in till hyresvärd!';
      }
      if (this.applicationSentToHost) {
        return 'Vi har nu skickat in er ansökan till hyresvärden.';
      }
      return 'Det saknas uppgifter under följande kategorier:';
    },
    rejectCategoriesLinks() {
      return this.rejectedCategories.map(category => {
        let computedCategory = category;
        if (category === 'computedDocumentsGroups') {
          computedCategory = 'documents';
        }
        return this.filteredMenu.find(item => item.key === computedCategory);
      });
    }
  },
  methods: {
    ...mapMutations({
      setRenewalModalVisible: 'app/setRenewalModalVisible'
    }),
    getUncompletedCategory(catArray) {
      if (!this.application) {
        return [];
      }
      return catArray.filter((link, index) => {
        const status = this.applicationCategoriesWithErrors[index];

        if (!this.application?.reason && link.statusName === 'DOCUMENTS') {
          return false;
        }

        return !this.completedStatuses.includes(status);
      });
    },
    redirectToSubmitPage() {
      this.$router.push('/granska');
    }
  }
};
</script>

<style lang="scss" scoped>
.dynamic-text-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .icon {
    width: 40px;
  }
  .dynamic-title {
    text-transform: uppercase;
  }

  .process-step-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .category-link-container {
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        .category-link {
          color: var(--color-sky-blue);
          text-decoration: underline;
          cursor: pointer;
          font-weight: 600;
        }
        .category-link:hover {
          color: var(--color-blue);
        }
      }
    }
  }
}
</style>
