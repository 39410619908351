export default {
  applicationIsSentAndApproved(state) {
    if (!state.application) {
      return false;
    }
    return (
      state.application.status === 'SENT' &&
      state.application.landlordStatus === 'APPROVED'
    );
  },
  applicationIsSentAndArchived(state) {
    if (!state.application) {
      return false;
    }

    const archivedStatuses = [
      'CLOSED',
      'REJECTED',
      'ABORTED',
      'REMOVED',
      'PENDING_REMOVE'
    ];

    return (
      state.application.status === 'SENT' &&
      archivedStatuses.includes(state.application.landlordStatus)
    );
  },
  applicationIsEditable(state) {
    if (!state.application) {
      return false;
    }
    return state.application.status === 'WAITING';
  },
  applicationCategoryRejected(state, getters) {
    return getters.rejectedCategories.some(category => !!category);
  },
  hasReadInfo(state) {
    return state.application?.hasReadInfo || false;
  },
  rejectedCategories(state) {
    if (!state.application) {
      return [];
    }

    const categories = [
      'currentTenants',
      'apartment',
      'reason',
      'documents',
      'computedDocumentsGroups',
      'secondaryTenants'
    ];

    return Object.keys(state.application).filter(key => {
      if (!categories.includes(key)) {
        return false;
      }

      const category = state.application[key];

      if (key === 'computedDocumentsGroups') {
        return Object.values(category || {}).some(
          doc => doc.status === 'REJECTED'
        );
      }

      if (Array.isArray(category)) {
        return category.find(cat => cat.status === 'REJECTED');
      }
      return category.status === 'REJECTED';
    });
  },
  infoMissing: (state, getters) => category => {
    const missingFields = getters.calculateMissingFields(category);
    return missingFields.length > 0;
  },
  categoryRejected: (state, getters) => category => {
    if (!state.application) {
      return [];
    }

    return !!getters.rejectedCategories.find(item => item === category);
  },
  currentTenantsHasError(state, getters) {
    return (
      getters.infoMissing(state.application.currentTenants[0]) ||
      (state.application.currentTenants[1] &&
        getters.infoMissing(state.application.currentTenants[1])) ||
      getters.categoryRejected('currentTenants')
    );
  },
  apartmentHasError(state, getters) {
    return (
      getters.infoMissing(state.application.apartment) ||
      getters.categoryRejected('apartment')
    );
  },
  reasonHasError(state, getters) {
    return (
      getters.infoMissing(state.application.reason) ||
      getters.categoryRejected('reason')
    );
  },
  documentsHasError(state, getters) {
    return (
      getters.categoryRejected('documents') ||
      getters.categoryRejected('computedDocumentsGroups') ||
      getters.documentsMissing
    );
  },
  secondaryTenantsHasError(state, getters) {
    return (
      getters.infoMissing(state.application.secondaryTenants[0]) ||
      (state.application.secondaryTenants[1] &&
        getters.infoMissing(state.application.secondaryTenants[1])) ||
      getters.categoryRejected('secondaryTenants')
    );
  },
  applicationCategoriesWithErrors(state, getters) {
    return {
      PERSONAL_INFORMATION: getters.currentTenantsHasError,
      APARTMENT: getters.apartmentHasError,
      REASON: getters.reasonHasError,
      DOCUMENTS: getters.documentsHasError,
      SUBTENANT_INFORMATION: getters.secondaryTenantsHasError
    };
  },
  applicationComplete(state, getters) {
    return Object.values(getters.applicationCategoriesWithErrors).every(
      error => !error
    );
  },
  calculateMissingFields: state => category => {
    let excludeFields = ['index', 'status', 'rejectReason'];

    if (category === state.application.reason) {
      excludeFields.push(
        'propertyOwner',
        'movingAbroad',
        'secondHouseOwner',
        'secondOwnerEmail',
        'secondOwnerPhone'
      );

      if (state.application.reason.type !== 'sambo') {
        excludeFields.push(
          'address',
          'postalCode',
          'partnerName',
          'partnerSsn',
          'housingType',
          'houseOwner',
          'ownerPhone',
          'ownerEmail'
        );
      }

      if (state.application.reason.housingType !== 'hyresratt') {
        excludeFields.push('nameOnContract');
      }

      if (state.application.reason.type !== 'other') {
        excludeFields.push('text');
      }
    } else if (category === state.application.apartment) {
      excludeFields.push('furnished');
    }

    return Object.keys(category).filter(
      key => !excludeFields.includes(key) && !category[key]
    );
  },
  documentsMissing(state, getters) {
    const documents = state.application.documents;
    const expectedDocuments = getters.requiredDocuments;
    const uploadedDocuments = Array.isArray(documents)
      ? documents.map(doc => doc.category)
      : [];

    let missingDocuments = [];

    if (expectedDocuments.length > 0) {
      missingDocuments = expectedDocuments.filter(
        document => !uploadedDocuments.includes(document)
      );
    } else {
      missingDocuments = [];
    }

    return (
      missingDocuments.length > 0 ||
      (expectedDocuments.length === 0 && uploadedDocuments.length === 0)
    );
  },
  requiredDocuments(state) {
    if (!state.application?.computedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.computedDocumentsGroups)
      .filter(([, props]) => props.required === 1)
      .map(item => item[0]);
  },
  noneRequiredDocuments(state) {
    if (!state.application?.computedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.computedDocumentsGroups)
      .filter(([, props]) => props.required === 0)
      .map(item => item[0]);
  }
};
