<template>
  <transition name="shuffle" appear>
    <div class="full-size-bg modal-bg">
      <div
        v-outside-click="handleClose"
        class="modal-wrapper flex-center"
        aria-modal="true"
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import outsideClick from '@/directives/outside-click';

export default {
  directives: { outsideClick },

  data: () => {
    return {
      isRendered: false,
      mobileCurrentScroll: 0
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile'
    })
  },

  mounted() {
    this.disableScroll();
    setTimeout(() => {
      this.isRendered = true;
    }, 100);
  },
  beforeDestroy() {
    this.enableScroll();
    window.removeEventListener('keydown', this.onKeyPress);
  },
  created() {
    window.addEventListener('keydown', this.onKeyPress);
  },

  methods: {
    onKeyPress(e) {
      if (e.keyCode == 27) {
        this.handleClose();
      }
    },
    disableScroll() {
      if (this.isMobile) {
        this.mobileCurrentScroll = document.documentElement.scrollTop;
        document.body.style.top = `${this.mobileCurrentScroll * -1}px`;
        document.body.classList.add('scroll-lock');
      }
    },
    enableScroll() {
      if (this.isMobile) {
        document.body.style.top = '';
        document.body.classList.remove('scroll-lock');
        window.scroll(0, this.mobileCurrentScroll);
      }
    },
    handleClose(e) {
      if (!this.isRendered) {
        return;
      }
      this.enableScroll();
      this.$emit('close', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.full-size-bg {
  position: fixed;
  z-index: 99;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  @media ($mobile) {
    align-items: stretch;
  }
}

.modal-wrapper {
  border-radius: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
