import axios from 'axios';
import apiClient from './apiClient';

async function getUserApplications() {
  return await apiClient.get('/portal/applications');
}

async function getApplication(id) {
  try {
    return await apiClient.get(`/portal/applications/${id}`);
  } catch {
    return null;
  }
}

async function getSubletApplication(landlordId) {
  const res = await apiClient.get(`/andrahand/form/applications/${landlordId}`);
  return res;
}

async function extendSubletApplication(landlordId) {
  const res = await apiClient.post(
    `/andrahand/form/applications/${landlordId}/extend`
  );
  return res?.data;
}

async function createNewSubletApplication(landlordId) {
  const res = await apiClient.post(
    `/andrahand/form/applications/${landlordId}/new`
  );
  return res?.data;
}

async function updateCurrentTenants(applicationId, currentTenants) {
  return await apiClient.put(
    `/andrahand/form/applications/currentTenant/${applicationId}`,
    currentTenants
  );
}

async function updateSecondaryTenants(applicationId, secondaryTenants) {
  return await apiClient.put(
    `/andrahand/form/applications/secondaryTenant/${applicationId}`,
    secondaryTenants
  );
}

async function updateApartment(applicationId, apartment) {
  return await apiClient.put(
    `/andrahand/form/applications/apartment/${applicationId}`,
    apartment
  );
}

async function updateReason(applicationId, reason) {
  return await apiClient.put(
    `/andrahand/form/applications/reason/${applicationId}`,
    reason
  );
}

async function updateHasReadInfo(applicationId) {
  return await apiClient.put(
    `andrahand/form/applications/userinfo/${applicationId}/read`
  );
}

async function getFile(applicationId, uuid) {
  const res = await apiClient.get(
    `/andrahand/form/documents/file/${applicationId}/${uuid}`
  );
  return res;
}

async function postFile(applicationId, file, category) {
  const formData = new FormData();
  formData.append('applicationId', applicationId);
  formData.append('file', file);
  formData.append('category', category);
  const { data } = await apiClient.post('/andrahand/form/documents', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
}

async function deleteFile(applicationId, uuid) {
  return await apiClient.del(
    `/andrahand/form/documents/file/${applicationId}/${uuid}`
  );
}

async function updateApplication(applicationId) {
  return await apiClient.put(
    `/andrahand/form/applications/submit/${applicationId}`
  );
}

async function getApplicationStatuses(applicantId) {
  const data = await apiClient.get(`/portal/applicants/${applicantId}/status`);

  return data;
}

async function createApplication(landlordId, landlordName) {
  const { data } = await apiClient.post(`/portal/applications`, {
    landlordId,
    landlordName
  });
  return data;
}

async function createNewApplicant(applicationId) {
  const { data } = await apiClient.post(
    `/portal/applications/${applicationId}/applicant`
  );
  return data;
}

async function inviteApplicant(applicantId, email) {
  const res = await apiClient.post(`/portal/applicants/${applicantId}/invite`, {
    inviteEmail: email
  });

  return res;
}

async function checkInvite(applicantId, email) {
  const { data } = await apiClient.post(
    `/portal/applicants/${applicantId}/checkinvite`,
    {
      inviteEmail: email
    }
  );
  return data;
}

async function getApplicationFromInvite(inviteCode) {
  try {
    return await apiClient.get(`/portal/applications/invite/${inviteCode}`);
  } catch {
    return null;
  }
}

async function removeApplicant(applicationId, applicantId) {
  return await apiClient.del(
    `/portal/applications/${applicationId}/applicant/${applicantId}`
  );
}

async function updateApartments(applicantId, apartments) {
  const res = await apiClient.put(
    `/portal/applicants/${applicantId}/apartments`,
    apartments
  );
  return res?.data;
}

async function updateSwapDetails(applicantId, swapDetails) {
  return await apiClient.put(
    `/portal/applicants/${applicantId}/swapDetails`,
    swapDetails
  );
}

async function updateMembers(applicantId, members) {
  const res = await apiClient.put(
    `/portal/applicants/${applicantId}/members`,
    members
  );
  return res;
}

async function connectAccount(applicantId, members) {
  const res = await apiClient.put(
    `/portal/applicants/${applicantId}/members/connectUser`,
    members
  );
  return res.data;
}

async function updateSwapReason(applicantId, swapReason) {
  return await apiClient.put(
    `/portal/applicants/${applicantId}/swapReason`,
    swapReason
  );
}

async function addSecondApartment(applicantId) {
  const { data } = await apiClient.post(
    `/portal/applicants/${applicantId}/apartments`
  );
  return data;
}

async function startApplicant(applicantId) {
  return await apiClient.put(
    `/portal/applicants/${applicantId}/startApplicant`
  );
}

async function removeSecondApartment(applicantId) {
  return await apiClient.del(`/portal/applicants/${applicantId}/apartments`);
}

async function removeApplication(applicationId) {
  return await apiClient.del(`/portal/applications/${applicationId}`);
}

async function searchLandLord(query) {
  try {
    const result = await apiClient.get(
      `/landlords/details/public?query=${query}&pageSize=999999`
    );

    return result;
  } catch {
    console.error('Fetch failed');
  }
}

async function getLandLord(subdomain) {
  try {
    const result = await apiClient.get(
      `/landlords/public/subdomain/${subdomain}`
    );
    return result;
  } catch {
    console.error('Fetch failed');
  }

  return null;
}

async function getParentLandLords(parentLandlordId) {
  return await apiClient.get(
    `/landlords/public/parentLandlord/${parentLandlordId}`
  );
}

async function searchPostalCode(query) {
  try {
    const result = await axios.get(
      `https://geo.lagenhetsbyte.se/search/se/${query}`
    );
    return result.data;
  } catch {
    console.error('Fetch failed');
  }
}

async function inactivateApplication(applicationId, applicantId) {
  return await apiClient.post(`/portal/applications/inactivateApplication`, {
    applicationId,
    applicantId
  });
}

async function reactivateApplication(applicationId, applicantId) {
  return await apiClient.post(`/portal/applications/reactivateApplication`, {
    applicationId,
    applicantId
  });
}

async function checkDuplicates(emails) {
  return await apiClient.get(
    `/portal/applications/duplicates?${emails
      .map((n, index) => `emails[${index}]=${n}`)
      .join('&')}`
  );
}

async function getLandlordFill(landlordId) {
  return await apiClient.get(`/landlords/details/fill/${landlordId}`);
}

async function submitApplicant(applicantId) {
  return apiClient.put(`/portal/applicants/${applicantId}/submit`);
}

export default {
  addSecondApartment,
  removeSecondApartment,
  removeApplicant,
  createNewApplicant,
  inviteApplicant,
  checkInvite,
  getApplicationFromInvite,
  createApplication,
  updateApartments,
  updateMembers,
  connectAccount,
  updateSwapReason,
  getApplication,
  getUserApplications,
  removeApplication,
  updateSwapDetails,
  searchLandLord,
  searchPostalCode,
  getLandLord,
  startApplicant,
  inactivateApplication,
  reactivateApplication,
  getApplicationStatuses,
  getLandlordFill,
  getSubletApplication,
  extendSubletApplication,
  createNewSubletApplication,
  updateCurrentTenants,
  updateSecondaryTenants,
  updateApartment,
  updateReason,
  updateHasReadInfo,
  getFile,
  postFile,
  deleteFile,
  updateApplication,
  checkDuplicates,
  getParentLandLords,
  submitApplicant
};
