import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  screenSize: 'DESKTOP',
  authenticated: false,
  appReady: false,
  isLoading: false,
  landlord: null,
  sideMenuVisible: false,
  supportAddress: 'support@andrahand.se',
  altSupportAddress: 'kundservice@andrahand.se',
  sideMenu: [
    {
      label: 'Dina uppgifter',
      url: '/uppgifter',
      key: 'currentTenants',
      statusName: 'PERSONAL_INFORMATION'
    },
    {
      label: 'Din lägenhet',
      url: '/lagenhet',
      key: 'apartment',
      statusName: 'APARTMENT'
    },
    {
      label: 'Ditt skäl',
      url: '/skal',
      key: 'reason',
      statusName: 'REASON'
    },
    {
      label: 'Ladda upp filer',
      url: '/dokument',
      key: 'documents',
      statusName: 'DOCUMENTS'
    },
    {
      label: 'Din andrahandshyresgäst',
      url: '/andrahandshyresgast',
      key: 'secondaryTenants',
      statusName: 'SUBTENANT_INFORMATION'
    },
    {
      label: 'Förhandsgranska',
      url: '/granska',
      key: '',
      statusName: 'DATA_SUMMARY'
    },
    {
      label: 'Vad händer nu?',
      url: '/status',
      key: '',
      statusName: 'STATUS'
    }
  ],
  tooltips: [],
  renewalModalVisible: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
