<template>
  <div class="document-wrapper">
    <InfoModal
      v-if="uploadError"
      title="Något gick fel"
      abort-label="Stäng"
      @click="$emit('uploadErrorModalClose')"
    >
      <p>
        Det gick inte att ladda upp filen/filerna. Försök igen senare.
      </p></InfoModal
    >
    <InfoModal
      v-if="fileTooLargeModalVisible"
      title="Se över filstorleken"
      abort-label="Stäng"
      @click="fileTooLargeModalVisible = false"
    >
      <p>
        <template v-if="uploadCount > 1"
          >Någon av filerna är för stor.</template
        >
        <template v-else>Filen är för stor.</template> En fil får max vara
        <strong>{{ maxFileSize / (1024 * 1024) }} MB</strong>.
      </p></InfoModal
    >
    <InfoModal
      v-if="notAcceptedModalVisible"
      title="Filtypen stöds inte"
      abort-label="Stäng"
      @click="notAcceptedModalVisible = false"
    >
      <p>
        Det gick inte att ladda upp filen/filerna eftersom filtypen inte stöds.
        Godkända filformat är
        <strong>{{ readableAcceptedFiles }}</strong
        >.
      </p></InfoModal
    >
    <InfoModal
      v-if="fileToDelete"
      title="Ta bort fil?"
      :confirm-label="!deleting ? 'Ta bort' : 'Tar bort...'"
      :abort-label="!deleting ? 'Stäng' : ''"
      :is-warning="true"
      :is-disabled="deleting"
      @click="handleModalClick"
    />
    <transition name="form" mode="out-in">
      <section :key="currentId">
        <input
          :id="inputId"
          ref="fileInput"
          multiple
          hidden
          :disabled="disabled"
          :accept="acceptedFiles"
          type="file"
          @change="checkFilesBeforeUpload($event.target.files)"
        />
        <label
          v-if="documents.length === 0"
          :for="inputId"
          class="upload-area"
          :title="infoTitle"
          :class="{
            dragging: dragging,
            disabled: disabled
          }"
          draggable
          @dragover.prevent="dragging = true"
          @dragleave.prevent="dragging = false"
          @drop.prevent="
            dragging = false;
            checkFilesBeforeUpload($event.dataTransfer.files);
          "
        >
          <template v-if="uploading">
            <base-loading-spinner class="loader"></base-loading-spinner>
            <p class="no-pointer-events">Laddar upp...</p>
          </template>
          <template v-else>
            <BaseImage
              :url="svgs.ICONS.ADD_DOCUMENT"
              class="upload-image no-pointer-events"
            ></BaseImage>
            <p class="upload-text no-pointer-events">
              Släpp filen här eller klicka för att ladda upp
              <span class="text-light">({{ readableAcceptedFiles }})</span>
            </p>
          </template>
        </label>

        <div class="documents-wrapper">
          <label
            v-if="documents.length > 0"
            :for="inputId"
            class="document-container upload-2-area document"
            :title="infoTitle"
            :class="{
              dragging: dragging,
              disabled: disabled
            }"
            draggable
            @dragover.prevent="dragging = true"
            @dragleave.prevent="dragging = false"
            @drop.prevent="
              dragging = false;
              checkFilesBeforeUpload($event.dataTransfer.files);
            "
          >
            <template v-if="uploading">
              <base-loading-spinner class="loader"></base-loading-spinner>
              <p class="upload-text-2 no-pointer-events">Laddar upp...</p>
            </template>
            <template v-else>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30 14H18V2C18 1.46957 17.7893 0.96086 17.4142 0.585787C17.0391 0.210714 16.5304 0 16 0C15.4696 0 14.9609 0.210714 14.5858 0.585787C14.2107 0.96086 14 1.46957 14 2V14H2C1.46957 14 0.96086 14.2107 0.585787 14.5858C0.210714 14.9609 0 15.4696 0 16C0 16.5304 0.210714 17.0391 0.585787 17.4142C0.96086 17.7893 1.46957 18 2 18H14V30C14 30.5304 14.2107 31.0391 14.5858 31.4142C14.9609 31.7893 15.4696 32 16 32C16.5304 32 17.0391 31.7893 17.4142 31.4142C17.7893 31.0391 18 30.5304 18 30V18H30C30.5304 18 31.0391 17.7893 31.4142 17.4142C31.7893 17.0391 32 16.5304 32 16C32 15.4696 31.7893 14.9609 31.4142 14.5858C31.0391 14.2107 30.5304 14 30 14Z"
                  fill="#409FFF"
                />
              </svg>
              <p class="upload-text-2 no-pointer-events">
                Ladda upp fler
                <br /><span class="text-light">(pdf, jpg, png)</span>
              </p>
            </template>
          </label>
          <div
            v-for="(item, index) of documents"
            :key="index"
            class="document-container"
          >
            <div
              :class="[
                'document',
                'document-file',
                Object.keys(invalidDocuments).includes(item[docIdentifier])
                  ? 'invalid'
                  : ''
              ]"
            >
              <button
                class="delete-image-button"
                :disabled="disabled"
                :title="infoTitle"
                @click="fileToDelete = item"
              >
                <BaseImage
                  :url="svgs.ICONS.DELETE_DOCUMENT"
                  class="delete-image"
                ></BaseImage>
              </button>

              <button
                class="simple-button document-text document"
                :class="{
                  'document-hover': item[docIdentifier] == downloadingId
                }"
                @click="openFile(item)"
              >
                <span v-if="downloadingId == item[docIdentifier]"
                  >Laddar ned...</span
                >
                <span v-else>Öppna</span>
              </button>

              <BaseImage :url="svgs.ICONS.DOCUMENT"></BaseImage>
            </div>
            <span class="mt-10"> {{ getFilename(item.fileName) }}</span>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import svgs from '@/utils/icon-constants';
import { getFilename } from '@/utils/common';
import BaseImage from '@/components/BaseImage.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'DocumentsUpload',
  components: {
    BaseImage,
    BaseLoadingSpinner,
    InfoModal
  },

  props: {
    acceptedFiles: {
      type: Array,
      default: () => [
        'application/pdf',
        'image/jpeg',
        'image/gif',
        'image/png',
        'image/heic'
      ]
    },
    currentId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    documents: {
      type: Array,
      required: true
    },
    downloadingId: {
      type: [String, Number],
      default: null
    },
    infoTitle: {
      type: String,
      default: ''
    },
    invalidDocuments: {
      type: Object,
      default: () => ({})
    },
    uploadError: {
      type: Boolean,
      default: false
    },
    docIdentifier: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      svgs,
      getFilename,
      dragging: false,
      uploading: false,
      uploadPercent: 0,
      deleting: false,
      fileToDelete: null,
      notAcceptedModalVisible: false,
      fileTooLargeModalVisible: false,
      maxFileSize: 15728640,
      uploadCount: 0
    };
  },

  computed: {
    readableAcceptedFiles() {
      return this.acceptedFiles.map(type => type.split('/')[1]).join(', ');
    },
    inputId() {
      return `file-${this._uid}`;
    }
  },
  methods: {
    checkFilesBeforeUpload(files) {
      if (this.disabled) {
        return;
      }
      const filesArr = [...files];
      this.uploadCount = filesArr?.length || 0;
      const notAccepted = filesArr.some(
        file => !this.acceptedFiles.includes(file.type)
      );
      const fileTooLarge = filesArr.some(file => file.size > this.maxFileSize);

      if (fileTooLarge) {
        this.fileTooLargeModalVisible = true;
      } else if (notAccepted) {
        this.notAcceptedModalVisible = true;
      } else {
        this.uploadFile(files);
      }
    },

    async removeFile(file) {
      this.deleting = true;

      const id = file[this.docIdentifier];

      await this.emitPromised('removeDocument', id);

      this.fileToDelete = null;
      this.deleting = false;
    },

    async uploadFile(files) {
      this.uploading = true;
      let uploadCount = 0;

      for (const file of files) {
        uploadCount++;
        this.uploadPercent = Math.floor((100 / files.length) * uploadCount);

        await this.emitPromised('addDocument', file);
      }

      this.uploading = false;
      this.uploadPercent = 0;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }
    },

    async openFile(file) {
      const id = file[this.docIdentifier];
      await this.emitPromised('openFile', id);
    },
    handleModalClick(eventType) {
      if (eventType === 'CONFIRM') {
        this.removeFile(this.fileToDelete);
      } else {
        this.fileToDelete = null;
      }
    },
    async emitPromised(method, ...params) {
      let listener =
        this.$listeners[method] || this.$attrs[method] || this[method];
      if (listener) {
        let res = await listener(...params);
        return res === undefined || res;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.documents-wrapper > *:not(:last-child) {
  margin-right: 20px;

  @media ($mobile) {
    margin-right: 10px;
  }
}

.documents-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: 75px;

  @media ($mobile) {
    margin-top: 3rem;
  }
}

.document-container {
  color: rgb(160, 160, 160);
  font-size: 14px;
  font-weight: 600;

  display: flex;
  flex-direction: column;

  max-width: 150px;
  word-break: break-all;
  margin-bottom: 10px;
}

.document {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 175px;
  width: 150px;
  background: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  &-file {
    overflow: hidden;
    &.invalid {
      box-shadow: 0px 0px 8px 3px var(--color-red);
    }
  }
}

.upload-image {
  height: 40px;
  width: 32px;
}

.loader {
  height: 75px;
}

.mt-10 {
  margin-top: 10px;
}

input::-webkit-file-upload-button {
  visibility: hidden;
}

.upload-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  padding: 20px;
  border: 3px dashed var(--color-blue);
  text-align: center;
  line-height: 1.3;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  background-color: #e9efff;
  font-size: 14px;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.text-light {
  color: rgb(160, 160, 160);
}

.upload-text {
  margin-top: 20px;
  width: 155px;
}

.upload-text-2 {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-dark);
}

.dragging {
  background-color: #c9d8ff !important;
}

.no-pointer-events {
  pointer-events: none;
}

.delete-image-button {
  height: 40px;
  width: 40px;
  position: absolute;
  right: 18px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
  transform: scale3d(1, 1, 1);
  will-change: transform;
  transition: 0.25s ease;
  &:not([disabled]):hover {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1);
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.upload-2-area {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9efff;
  border: 3px dashed var(--color-blue);
  border-radius: 20px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.document-wrapper {
  color: var(--color-dark);
}

.document-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  border-radius: 0;
  will-change: opacity;
  transition: 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
}

@media ($desktop) {
  .document-text:hover,
  .document-hover {
    opacity: 1;
    background-color: rgba(88, 88, 88, 0.77);
  }
}
</style>
