<template>
  <InfoModal
    v-if="confirmExtension"
    :is-disabled="isLoading"
    :show-close-btn="!isLoading"
    :is-loading="isLoading"
    title="Bekräfta förläng ansökan"
    mobile-full-width
    abort-label="Avbryt"
    confirm-label="Ja, förläng ansökan"
    @click="confirmExtensionClick"
  >
    <div class="wrapper">
      <h2>Är du säker på att du vill förlänga?</h2>
      <p>
        Vi kommer automatiskt kopiera över befintlig
        <strong>information om dig och din lägenhet</strong> till den nya
        ansökan, vilket gör processen snabb och smidig. Ansökan kommer att
        granskas på nytt av oss för att sedan skicka den till din hyresvärd.
      </p>
    </div>
  </InfoModal>
  <InfoModal
    v-else-if="confirmNew"
    :is-disabled="isLoading"
    :show-close-btn="!isLoading"
    :is-loading="isLoading"
    title="Bekräfta ny ansökan"
    mobile-full-width
    abort-label="Avbryt"
    confirm-label="Ja, skapa ny ansökan"
    @click="confirmNewClick"
  >
    <div class="wrapper">
      <h2>Är du säker på att du vill skapa en ny ansökan?</h2>
      <p>
        I den nya ansökan fyller du i alla relevanta uppgifter från grunden.
        Ansökan granskas sedan av oss för att säkerställa att allt är korrekt
        innan den skickas vidare till din hyresvärd.
      </p>
    </div>
  </InfoModal>
  <InfoModal
    v-else
    :is-disabled="isLoading"
    :show-close-btn="!isLoading"
    :is-loading="isLoading"
    title="Hantera ny ansökan"
    mobile-full-width
    @click="handleExtendClick"
  >
    <div class="wrapper">
      <template v-if="applicationIsSentAndApproved">
        <h2>Aktiv uthyrning</h2>
        <p>
          Grattis! Din ansökan om andrahandsuthyrning har blivit godkänd. Din
          uthyrningsperiod är från <strong>{{ startDate }}</strong> till
          <strong>{{ endDate }}</strong
          >. Under denna period är det viktigt att följa avtalet som är
          upprättat med din hyresvärd.
        </p>
        <h2>Önskar du att förlänga?</h2>
        <p>
          Om du vill förlänga din andrahandsuthyrning kan du enkelt ansöka om en
          förlängning. Vi kommer automatiskt kopiera över befintlig
          <strong>information om dig och din lägenhet</strong> till den nya
          ansökan, vilket gör processen snabb och smidig. Ansökan kommer att
          granskas på nytt av oss för att sedan skicka den till din hyresvärd.
        </p>
        <BaseButton
          :is-rounded="true"
          type="button"
          class="modal-btn modal-btn-extend"
          :disabled="isLoading"
          :is-loading="isLoading"
          @click="confirmExtension = true"
        >
          Förläng ansökan
          <BaseIconText
            class="info-modal-arrow-right"
            :icon-url="svgs.ICONS.ARROW_RIGHT"
          >
          </BaseIconText>
        </BaseButton>
      </template>
      <h2>Vill du skapa en ny ansökan?</h2>
      <p>
        Du kan skapa en helt ny ansökan om andrahandsuthyrning. I en ny ansökan
        fyller du i alla relevanta uppgifter från grunden, inklusive information
        om bostaden, hyresgästen och den tilltänkta uthyrningsperioden. Ansökan
        granskas sedan av oss för att säkerställa att allt är korrekt innan den
        skickas vidare till din hyresvärd.
      </p>
      <BaseButton
        :is-rounded="true"
        type="button"
        class="modal-btn"
        :disabled="isLoading"
        :is-loading="isLoading"
        @click="confirmNew = true"
      >
        Skapa ny ansökan
        <BaseIconText
          class="info-modal-arrow-right"
          :icon-url="svgs.ICONS.ARROW_RIGHT"
        >
        </BaseIconText>
      </BaseButton>
    </div>
  </InfoModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import svgs from '@/utils/icon-constants';

import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';

export default {
  name: 'SubletIsActiveModal',
  components: {
    InfoModal,
    BaseButton,
    BaseIconText
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgs,
      confirmExtension: false,
      confirmNew: false
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsSentAndApproved:
        'subletApplication/applicationIsSentAndApproved',
      applicationIsSentAndArchived:
        'subletApplication/applicationIsSentAndArchived'
    }),
    startDate() {
      return new Date(this.application?.startDate).toLocaleDateString('sv-SE');
    },
    endDate() {
      return new Date(this.application?.endDate).toLocaleDateString('sv-SE');
    }
  },
  methods: {
    handleExtendClick(response) {
      if (response === 'CANCEL') {
        this.$emit('response', 'CLOSE');
      }
    },
    confirmExtensionClick(response) {
      if (response === 'CONFIRM') {
        this.$emit('response', 'EXTEND');
      } else {
        this.confirmExtension = false;
      }
    },
    confirmNewClick(response) {
      if (response === 'CONFIRM') {
        this.$emit('response', 'CREATE_NEW');
      } else {
        this.confirmNew = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  h2 {
    margin: 0 0 0rem;
  }
  p {
    margin: 0 0 2rem;
    line-height: 1.6;
  }
  > *:nth-last-child(1) {
    margin: 0;
  }
}
.btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;

  &.btn-position-start {
    justify-content: flex-start;
  }
  @media ($mobile) {
    flex-direction: column;
    gap: 1rem;
  }
}

.modal-btn {
  padding-right: 2rem;
  padding-left: 2rem;
  height: 40px;

  @media ($mobile) {
    width: 100%;
  }
}

.modal-btn-extend {
  margin-bottom: 1.5rem;
}
</style>
